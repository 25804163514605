<template>
  <v-container class="SelfieIdentityCard">
    <v-row justify="center">
      <v-col cols="12" align="center">
        <h3> {{$t('SelfiewithyourStudentID')}}</h3> 
        <p>{{$t('Makesureyourfaceisintheovalarea')}}</p>
      </v-col>
      <v-col cols="10" class="pt-0">
        <v-img 
          :src="require('@/assets/kyc/selfiecardexample.svg')"></v-img>
      </v-col>
    </v-row>
    <v-container class="footer">
      <v-row justify="center">
        <v-col cols="8">
          <v-btn 
            rounded
            block
            color="primary"
            @click="clickSelfieIdentityCard()">{{$t('Next')}}
          </v-btn>
        </v-col>
      </v-row> 
    </v-container>
  </v-container>
</template>

<script>

  export default {
    name:"SelfieIdentityCardPremium",
    components: { },
    data() {
      return {
        notifSuccess:false,
        notifFailed:false,
      }
    },
    methods:{ 
      clickSelfieIdentityCard(){
        this.$router.push({
          name: 'CameraOnSelfieIdentityCardPremium',
        })
      },
      
      
    },
    mounted(){
      
    }
  }
</script>


<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0.5rem 0; 
  cursor: pointer;
}
</style>
